<template>
  <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
    <a-form-model-item :label="$t('hat.sos.form.status')" prop="disposeStatus">
      <a-select v-model="form.disposeStatus">
        <a-select-option :value="0">
          {{ $t('hat.sos.columns.pendingProcessing') }}
        </a-select-option>
        <a-select-option :value="1">
          {{ $t('hat.sos.columns.processed') }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item :label="$t('hat.sos.form.reason')" prop="disposeContent">
      <a-input
        v-model="form.disposeContent"
        :placeholder="$t('hat.sos.form.placeholder')"
        :maxLength="140"
      ></a-input>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { putStatus } from '@/services/smart-hat/sos-record';

@Component()
export default class SOSDispose extends Vue {
  @Prop({ type: Object, default: () => {} }) data;

  mounted() {
    this.initData();
  }
  form = {
    disposeStatus: 0,
    disposeContent: '',
    messageId: '',
  };
  rules = {
    disposeStatus: [
      {
        required: true,
        message: '当前状态不能为空',
      },
    ],
    disposeContent: [
      {
        required: true,
        message: this.$t('hat.sos.form.rule'),
      },
    ],
  };
  async initData() {
    this.form.disposeStatus = this.data.disposeStatus ? 1 : 0;
    this.form.disposeContent = this.data.disposeContent;
  }
  async getValue() {
    return new Promise((resolve, reject) => {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.form.messageId = this.data.messageId;
            await putStatus(this.form);
            this.$message.success(
              this.$t('hat.deviceManagement.editModal.modified'),
            );
            resolve(true);
          } catch {
            reject(false);
          }
        } else {
          reject(false);
        }
      });
    });
  }
}
</script>

<style lang="less" module></style>
