import request from '../request';

const serviceName = '/iot-smart-helmet/device/message';

export function getSOSList(data) {
  return request(`${serviceName}/sos`, {
    method: 'GET',
    body: data,
  });
}

export function putStatus(data) {
  return request(`${serviceName}/dispose`, {
    method: 'PUT',
    body: data,
  });
}
