<template>
  <div :class="$style.mapWrap">
    <div
      :class="$style.boxMapwrap"
      :style="{
        width: bigScreen ? '100vw' : '820px',
        height: bigScreen ? '100vh' : '500px',
        position: bigScreen ? 'fixed' : '',
        top: '-10px',
        left: 0,
        zIndex: 9999,
      }"
    >
      <div :class="$style.fance" :style="{ top: bigScreen ? '20px' : '10px' }">
        <a-select
          style="width: 150px;background-color: #ffff; "
          v-model="searchForm.fence"
          :placeholder="$t('hat.clockRecord.electronicFence')"
          allow-clear
          @change="handleSelectFence"
        >
          <a-select-option
            v-for="item in fenceList"
            :key="item.geofenceId"
            :value="item.geofenceId"
          >
            {{ item.geofenceName }}
          </a-select-option>
        </a-select>
      </div>
      <div :class="$style.screen" style="z-index: 9999999;">
        <x-icon
          :type="bigScreen ? 'tc-icon-fullscreen-exit' : 'tc-icon-fullscreen'"
          :class="$style.icon"
          @click="handleScreen"
        />
      </div>
      <div
        :class="$style.boxMap"
        id="boxMap"
        :style="{
          width: bigScreen ? '100vw' : '820px',
          height: bigScreen ? '100vh' : '500px',
        }"
      ></div>
    </div>

    <div :class="$style.footer">
      <a-button
        v-if="!bigScreen"
        size="small"
        type="primary"
        @click="handleClose"
      >
        {{ $t('enterpriseManagement.camera.detail.closetxt') }}
      </a-button>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { funAMap } from '../../electron-fence/components/util';
import { getFenceListById } from '@/services/smart-hat/geofence';
import { getZonesData } from '@/services/smart-hat/geofence';
import {
  drawCharts,
  formatToApi,
  mouseEvent,
} from '@/views/hat/electron-fence/components/util';
@Component({})
export default class SOSMap extends Vue {
  @Prop({ type: Object, default: () => {} }) record;
  bigScreen = false;
  map = null;
  disabled = true;
  searchForm = {
    fence: undefined,
  };
  fenceList = [];
  circleMarker = [];
  handleSelectFence(id) {
    this.deleteCircle();
    id && this.handleFenceChange(id, this.fenceList);
  }
  handleScreen() {
    this.bigScreen = !this.bigScreen;
  }
  handleClose() {
    this.$listeners.handleClose();
  }
  async mounted() {
    this.initMap();
    this.getFence();
  }

  async getFence() {
    const { userId } = this.record;
    const result = await getFenceListById({ userIds: userId });
    this.fenceList = result;
  }
  async initMap() {
    let { messageTime, lng, lat } = this.record;
    const AMap = await funAMap({
      plugins: ['AMap.PolygonEditor', 'AMap.CircleEditor', 'AMap.PlaceSearch'],
    });
    this.AMap = AMap;
    const { mode } = this.$store.state.crossStorage.skin;
    this.map = new AMap.Map('boxMap', {
      zoom: 17,
      center: [lng, lat],
      mapStyle: mode === 'dark' ? 'amap://styles/dark' : '',
    });
    this.addMarker(
      lng,
      lat,
      `${this.$moment(messageTime).format('HH:mm:ss')}${this.$t(
        'hat.sos.triggerSOS',
      )}`,
      -52,
    );
  }

  //标点  经度 维度
  addMarker(longitude, dimension, tip, location = 0) {
    // 创建一个 Icon
    var startIcon = new this.AMap.Icon({
      // 图标尺寸
      // size: new this.AMap.Size(100, 100),
      // 图标的取图地址
      image:
        'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
      // 图标所用图片大小
      imageSize: new this.AMap.Size(20, 30),
      // 图标取图偏移量
      imageOffset: new this.AMap.Pixel(0, 0),
    });

    // 将 icon 传入 marker
    var startMarker = new this.AMap.Marker({
      position: new this.AMap.LngLat(longitude, dimension),
      icon: startIcon,
      offset: new this.AMap.Pixel(-13, -30),
    });
    startMarker.setLabel({
      direction: 'bottom',
      offset: new this.AMap.Pixel(5, location + 52), //设置文本标注偏移量
      content: `<div >${tip}</div>`, //设置文本标注内容
    });
    this.map.add([startMarker]);
  }
  handleDisabledChange(disabled) {
    disabled && this.deleteCircle();
    !disabled && this.addCircle();
    this.disabled = disabled;
  }
  deleteCircle() {
    this.circleMarker.forEach(item => {
      this.map.remove(item);
    });
  }
  addCircle() {
    this.circleMarker.forEach(item => {
      item.setMap(this.map);
    });
  }
  async handleFenceChange(id, fenceList) {
    const item = fenceList.find(v => v.geofenceId === id);
    if (item.point && item.point.lng) {
      this.map.setCenter([item.point.lng, item.point.lat]);
    }
    const array = await getZonesData({
      geofenceId: id,
    });
    this.zoneArray = formatToApi(array);
    const list = drawCharts(this.zoneArray, this.AMap, (a, b) =>
      mouseEvent(a, b, this.map),
    );
    list.forEach(item => {
      this.circleMarker.push(item);
    });
    this.map.add(list);
  }
}
</script>
<style lang="less" module>
:global .ant-select-dropdown {
  z-index: 9999999;
}
.boxMapwrap {
  position: relative;
  .fance {
    position: absolute;
    right: 10px;
    z-index: 9999;
  }
  .screen {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 99;
  }
  .icon {
    font-size: 30px;
    color: var(--primary);
  }
}
.mapWrap {
  .footer {
    margin-top: 10px;
    display: flex;
    height: 40px;
    flex-direction: row-reverse;
    .tip {
      margin-left: 20px;
    }
  }
}
</style>
